<template>
<v-card flat class="mt-5">
    <v-card-text>
        <div id="app">
 <v-card
   
    max-width="700"
    outlined
  >
    <v-list-item three-line>
      <v-list-item-content>
     
        <v-list-item-title class="text-h5 mb-2">
          Matricule Institution : {{ this.reference_matricule }}
        </v-list-item-title>
        <v-list-item-subtitle>information : {{ this.information }}</v-list-item-subtitle>
      </v-list-item-content>


    </v-list-item>

    <v-card-actions>
      <v-btn
        outlined
        rounded
        dark
        color="green"
       @click="dialogmatricule=true"
      >
        Entrer Matricule Institution
      </v-btn>
    </v-card-actions>
  </v-card>

                          <v-dialog v-model="dialogmatricule" max-width="500px">

                           
                            <v-card>
                                <v-card-title>
                                    <span class="text-h5">Formulaire de verification</span>
                                </v-card-title>

                                <v-card-text>
                                    <v-container>
                                        <v-form ref="form" v-model="valid" lazy-validation>
                                            <v-text-field v-model="matricule" label="Matricule Institution*" dense outlined required></v-text-field>
                                        </v-form>

                                    </v-container>
                                </v-card-text>

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="red darken-1" text @click="dialogmatricule=false">
                                        Fermer
                                    </v-btn>
                                    <v-btn color="green darken-1" text @click="this.read_matricule_All">
                                        Valider
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>

                   <v-data-table :headers="headers" :items="list" sort-by="code" class="elevation-1">
                <template v-slot:[`item.image`]="{ item }">
                    <div class="p-2">
                        <v-img :src="lienimage+item.image" :alt="item.libelle" height="50px" width="50"></v-img>
                    </div>
                </template>
                <template v-slot:top>
                    <v-toolbar flat>
                        <v-toolbar-title>Souscription ODD Extrat</v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-spacer></v-spacer>
                        <v-dialog v-model="dialog" max-width="500px">

                            <v-snackbar v-model="snackbar" :multi-line="multiLine">
                                {{ text }}

                                <template v-slot:action="{ attrs }">
                                    <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
                                        Fermer
                                    </v-btn>
                                </template>
                            </v-snackbar>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on" @click="nouveau()">
                                    Nouveau
                                </v-btn>
                            </template>
                            <v-card>
                                <v-card-title>
                                    <span class="text-h5">{{ formTitle }}</span>
                                </v-card-title>

                                <v-card-text>
                                    <v-container>
                                        <v-form ref="form" v-model="valid" lazy-validation>
                                            <v-text-field v-model="reference_matricule" label="Matricule Institution*" disabled dense outlined required></v-text-field>
                                         
                                            <v-select :items="listpays" v-model="selectpays" @change="paysSearch" dense item-value="id" outlined label="Liste des pays">
                                                <template slot="selection" slot-scope="data">
                                                    {{ data.item.code_pays }} - {{ data.item.libelle }}
                                                </template>
                                                <template slot="item" slot-scope="data">
                                                    {{ data.item.code_pays }} - {{ data.item.libelle }}
                                                </template>
                                            </v-select>


                                            <v-select :items="listcible" v-model="selectcible" @change="cibleSearch" dense item-value="id" outlined label="Liste des cibles">
                                                <template slot="selection" slot-scope="data">
                                                    {{ data.item.code }} - {{ data.item.description }}
                                                </template>
                                                <template slot="item" slot-scope="data">
                                                    {{ data.item.code }} - {{ data.item.description }}
                                                </template>
                                            </v-select>

                                            <v-select :items="listindicateur" v-model="selectindicateur" dense item-value="id" outlined label="Liste des Indicateurs">
                                                <template slot="selection" slot-scope="data">
                                                    {{ data.item.code }} - {{ data.item.libelle }}
                                                </template>
                                                <template slot="item" slot-scope="data">
                                                    {{ data.item.code }} - {{ data.item.libelle }}
                                                </template>
                                            </v-select>

                                        </v-form>

                                    </v-container>
                                </v-card-text>

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blue darken-1" text @click="dialog=false">
                                        Fermer
                                    </v-btn>
                                    <v-btn color="blue darken-1" text @click="save">
                                        Enregistrer
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-dialog v-model="dialogDelete" max-width="500px">
                            <v-card>
                                <v-card-title class="text-h5">Voullez vous vraiment supprimer?</v-card-title>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blue darken-1" text @click="dialogDelete=false">Annuller</v-btn>
                                    <v-btn color="blue darken-1" text @click="deleteItemConfirm">Confirmer</v-btn>
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-toolbar>
                </template>
            
                <template v-slot:[`item.actions`]="{ item }">
                    <v-icon small class="mr-2" color="green" @click="editItem(item)">
                        mdi-pencil
                    </v-icon>
                    <v-icon color="red" class="mr-2" small @click="deleteItem(item)">
                        mdi-delete
                    </v-icon>
                </template>
                <template v-slot:no-data>
                    <v-btn color="primary" @click="initialize">
                        Actualiser la liste
                    </v-btn>
                </template>


                  <template v-slot:[`item.indicateurodd_extrat`]="{ item }">
                    {{ item.indicateurodd_extrat.code }} - {{ item.indicateurodd_extrat.libelle }}
                </template>

            </v-data-table>

        </div>

    </v-card-text>
</v-card>
</template>

<script>
import api from './../../../serviceApi/apiService'

export default {
    props: {
        informationData: {
            type: Object,
            default: () => {},
        },
    },
    data: () => ({
        multiLine: true,
        snackbar: false,
        snackbars: false,
        text: `message`,
        textsnackbar: 'messages',
        dialog: false,
        dialogDelete: false,
        dialogmatricule: false,
        headers: [{
                text: 'ID',
                value: 'id',
                divider: true,
                width: '10%',
            },
            {
                text: 'Date',
                align: 'start',
                value: 'created_at',
                divider: true,
                width: '20%'
            },
            {
                text: 'Reference',
                value: 'code',
                divider: true,
                width: '20%'
            },
             { text: 'Indicateur Odd', align: 'start', sortable: false,value: 'indicateurodd_extrat',divider: true,width: '40%'},
          
           
           

            {
                text: 'Actions',
                value: 'actions',
                sortable: false,
                divider: true,
                width: '20px'
            },
        ],
        formTitle: 'Formulaire de Souscription',
        list: [],
        listpays: [],
        listinfo: [],
        listcible: [],
        listindicateur: [],
        listindicateur2: [],
        listindicateur3: [],

        selectcible: '',
        selectpays: '',
        code: '',
        matricule: '',
        selectindicateur: '',
        valid: true,
        id_cibles: '0',
        id_indicateur: '0',
        reference: '',
        moduleodd: 'odd',
        textetat: false,
        messages: '',
        information:'',
        reference_matricule:'',

    }),

    methods: {
        //=====Affichages  liste des odds==========
        readAll: async function () {
             let fd = new FormData();
             fd.append('matricule', this.reference_matricule);
             fd.append('module', 'ODD');
             const data = await api.createUpdatedata('souscription/all-souscription-odd-extrat', fd);
             this.list = data.list;
        },

          //=====Affichages  liste des odds==========
        read_matricule_All: async function () {
           // const data = await api.readAll('souscription/verifie-souscription-odd-extrat');
            let fd = new FormData();
            fd.append('matricule', this.matricule);
            const data = await api.createUpdatedata('souscription/verifie-souscription-odd-extrat', fd);
             this.list = data.list;
              this.listinfo = data.list_info;
              if(data.status==200){
               this.information=this.listinfo[0].libelle+' email '+this.listinfo[0].email+' adresse '+this.listinfo[0].adresse;
               this.reference_matricule=this.listinfo[0].matricule;
               this.dialogmatricule=false;
              }else{
                this.reference_matricule='xxxxxxx';
                this.information='xxxxxxxxx';
              }
              
    
    },

        readcibleAll: async function () {
            const data = await api.readAll('cibles/all');
            this.listcible = data.list;
            // console.log(this.listcible);
        },

        readAll_pays_cible_indicateur: async function () {
            const data = await api.readAll('global-indicateur-cible-indicateur-extrat-pays/all');

            this.listpays = data.list_pays;
            this.listpays2 = data.list_pays;

            this.listcible = data.list_cible;
            //this.listcible2 = data.list_cible;
             this.listindicateur3 = data.list_indicateur_extrat;

        },

        readindicateurAll: async function () {
            const data = await api.readAll('indicateur-odd/all');
            this.listindicateur = data.list;
            //this.listindicateur2 = data.list;
        },

        deleteData: async function () {
            let fd = new FormData();
            fd.append('id', this.id_odds);
            const res = await api.createUpdatedata('souscription/delete', fd);
            this.messagesligne(res);
        },

        createUpdatedata: async function () {

            let fd = new FormData();
            fd.append('id_indicateur', this.selectindicateur);
            fd.append('module', 'ODD');
            fd.append('matricule', this.reference_matricule);

            if (this.textetat) {

                const res = await api.createUpdatedata('souscription/update-souscription-odd-extrat/' + this.id, fd);
                
               if(res.status==200){
                     this.text =res.message;
                this.snackbar = true;
                this.clear();
                    }else{
                this.text =res.message;
                this.snackbar = true;
                    } 
               
            } else {
                const res = await api.createUpdatedata('souscription/add-souscription-odd-extrat', fd);
               if(res.status==200){
                     this.text =res.message;
                this.snackbar = true;
                this.clear();
                    }else{
                this.text =res.message;
                this.snackbar = true;
                    }
            }
            this.readAll();
            
          
        },
        initialize() {
            this.readAll();
        },
        cibleSearch() {
            this.listindicateur= [];
            this.listindicateur = this.listindicateur3.filter(this.filtrerParID);

        },
            paysSearch() {
            this.listindicateur= [];
            this.listindicateur = this.listindicateur3.filter(this.filtrerParID);

        },
        filtrerParID: function (obj) {
            // Si c'est un nombre
            if (obj.id_cibles == this.selectcible && obj.id_pays == this.selectpays) {
                //console.log(this.selecteodd);
                return true;
            } else {
                // elementsInvalides++;
                return false;
            }
        },
         filtrer_pays_ParID: function (obj) {
            // Si c'est un nombre
            if (obj.id_cibles == this.selectcible && obj.id_pays == this.selectpays) {
                //console.log(this.selecteodd);
                return true;
            } else {
                // elementsInvalides++;
                return false;
            }
        },
        nouveau() {
            this.matricule = "";
            this.id_indicateur = "0";
            this.textetat = false;
            this.selectcible = '';
            this.selectindicateur = '';

        },


        clear() {
            this.matricule = "";
            this.id_indicateur = "0";
            this.textetat = false;
            this.selectcible = '';
            this.selectindicateur = '';
            this.textetat = false;
            //this.dialog=false;
        },
        deleteItem(item) {
            this.id_odds = item.id;
            this.dialogDelete = true;

        },
        editItem(item) {
            this.id = item.id;
            this.selectcible;
            this.selectindicateur;
            //this.code = item.code;
            this.textetat = true;
            this.dialog = true;
        },

        deleteItemConfirm() {
            this.deleteData();
            this.readAll();
            this.dialogDelete = false;
        },

        save() {
            if (this.reference_matricule == '') {
                this.text = "Veuillez Inserer matricule"
                this.snackbar = true;
                return;
            }

            this.createUpdatedata();

        },

        parcoure_indicateur: function filtrerParID(obj) {
            if (obj.id_cibles == this.selectcible && obj.id_pays == this.selectpays) {
                return true;
            } else {

                return false;
            }
        },

    },

    mounted() {
       this.readAll();
       this.readAll_pays_cible_indicateur();
        //this.readindicateurAll();
    },

}
</script>
